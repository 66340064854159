import { Button, Grid } from '@material-ui/core'
import { makeStyles, TextField } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  top: {
    height: '50%',
    backgroundColor: '#40798C',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '2em',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#336170',
    },
  },
  bottom: {
    height: '50%',
    backgroundColor: '#70A9A1',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '2em',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#548C85',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    backgroundColor: '#548C85',
    height: '100%',
  },
}))

export const Dashboard = () => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [value, setValue] = useState<string>()
  console.log(id)
  if (!id)
    return (
      <div className={classes.wrapper}>
        <Grid container direction="column" justify="center" spacing={2} alignContent="center" alignItems="center">
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              value={value}
              label="Spielraum Name"
              onChange={event => setValue(event.target.value)}
              autoComplete="name"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={() => history.push(`/${value}`)} disabled={!value}>
              User oder Admin wählen
            </Button>
          </Grid>
        </Grid>
      </div>
    )

  return (
    <>
      <div className={classes.top} onClick={() => history.push(`/${id}/user`)}>
        Anzeigegerät
      </div>
      <div className={classes.bottom} onClick={() => history.push(`/${id}/admin`)}>
        Admin
      </div>
    </>
  )
}
