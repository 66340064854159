import React from 'react'
import AdminView from './views/admin/AdminView'
import UserView from './views/user/UserView'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import { Dashboard } from './views/Dashboard'

let theme = createMuiTheme()
theme = responsiveFontSizes(theme)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path={'/:id/admin'} component={AdminView} />
          <Route path={'/:id/user'} component={UserView} />
          <Route path={'/:id'} component={Dashboard} />
          <Route path={'/'} component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
