import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Grid, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import { VisualContainer } from '../../components/VisualContainer'
import { User, SetItem } from '../../interfaces'
import {
  SocketService,
  startRedDotGame,
  stopGame,
  startAddNumbersGame,
  startSubtractNumbersGame,
  startDivideNumbersGame,
  startMultiplicateNumbersGame,
  startColorsGame,
  startFormsGame,
  resetClients,
} from '../../services/socket.service'
import { loadItem, saveItem, removeItem } from '../../services/cache.service'
import { Colors, GameDescriptions, Games } from '../../constants'
import useWindowDimensions from '../../hooks/windowDimensions'
import classnames from 'classnames'
import Div100vh from 'react-div-100vh'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f3f3f3',
    height: '100%',
    textAlign: 'center',
  },
  rootOverride: {
    minHeight: '-webkit-fill-available',
  },
  gameControl: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-around',
    margin: 'auto',
    paddingBottom: '16px',
    bottom: 0,
    position: 'absolute',
    justify: 'center',
    width: '100vw',
    maxWidth: '100vw',
    overflow: 'hidden',
  },
  settings: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
    height: '100%',
  },
  divider: {
    borderTop: '3px solid #3D8434',
  },
  controler: {
    backgroundColor: '#132332',
    height: '42vh',
    width: '100%',
    padding: '2vh',
  },
  direction: (props: { portrait: boolean }): any => ({
    maxHeight: '100%',
    borderRadius: '50%',
    height: props.portrait ? '18vh' : '30vh',
    width: props.portrait ? '18vh' : '30vh',
    border: '3px solid white',
    color: 'white',
    zIndex: 2,
    backgroundColor: '#132332',
    overflow: 'hidden',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      height: props.portrait ? '15vh' : '30vh',
      width: props.portrait ? '15vh' : '30vh',
    },
  }),
  controlDivider: {
    width: '100%',
    borderTop: '3px solid white',
    zIndex: 1,
    paddingBottom: '1em',
  },
  connector: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2em',
    top: '50%',
  },
  line: {
    borderTop: '3px solid white',
    height: '1px',
    width: '100%',
  },
  controlTitle: {
    height: '30%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  typography: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '1.1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em',
    },
  },
  startButtonWrapper: (props: { portrait: boolean }): any => ({
    width: props.portrait ? '15vw' : '12vh',
    height: props.portrait ? '15vw' : '12vh',
    borderRadius: '35%',
    overflow: 'hidden',
    boxShadow: '0 0 0 3px white, 0 0 0 5px #25A2F0',
  }),
  stopButtonWrapper: (props: { portrait: boolean }): any => ({
    width: props.portrait ? '15vw' : '12vh',
    height: props.portrait ? '15vw' : '12vh',
    borderRadius: '35%',
    overflow: 'hidden',
    boxShadow: '0 0 0 3px white, 0 0 0 5px #D5000C',
  }),
  equation: (props: { portrait: boolean }): any => {
    return {
      border: '3px solid white',
      whiteSpace: 'pre-wrap',
      width: props.portrait ? '37vw' : '30vh',
      height: props.portrait ? '30vw' : '20vh',
      borderRadius: '45%',
      overflow: 'hidden',
      color: 'white',
      fontSize: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2em',
      },
      [theme.breakpoints.up('md')]: {
        height: props.portrait ? '25vw' : '20vh',
        width: props.portrait ? '37vw' : '30vh',
      },
    }
  },
  startButton: {
    height: '100%',
    width: '100%',
    backgroundColor: '#312F30',
    color: '#25A2F0',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#06006a',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em',
      padding: 0,
    },
  },
  stopButton: {
    height: '100%',
    width: '100%',
    backgroundColor: '#312F30',
    color: '#D5000C',
    fontWeight: 'bold',
    fontSize: '1.3em',
    '&:hover': {
      backgroundColor: '#790007',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em',
      padding: 0,
    },
  },
  resetButton: {
    borderRadius: '5%',
    backgroundColor: '#312F30',
    color: '#D5000C',
    position: 'fixed',
    right: 10,
    top: 10,
    fontSize: '0.5em',
  },
  controlSelector: {
    width: '80%',
    color: 'white',
  },
  selectRoot: {
    color: 'white',
    background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(100,107,114,1) 6%, rgba(31,41,51,1) 45%)',
    border: '1px solid white',
    '&:focus': {
      borderRadius: 7,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  iconOutlined: {
    color: 'white',
  },
  select: {
    border: '1px solid white',
    borderRadius: '5%',
  },
  visualContainer: {
    height: '50vh',
    zIndex: 1000,
    top: 0,
    [theme.breakpoints.up('md')]: {
      height: '50vh',
    },
  },
}))

export const AdminView: React.FC = (): ReactElement | null => {
  const [socketService, setSocketService] = useState<SocketIOClient.Socket>()
  const [loading, setLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<User[]>([])
  const [gameStarted, setGameStarted] = useState<boolean>(false)
  const [positioning, setPositioning] = useState<'line' | 'circle'>(
    (loadItem('positioning') as 'line' | 'circle') ?? 'line'
  )
  const { height, width } = useWindowDimensions()
  const classes = useStyles({ portrait: height > width })
  const { id } = useParams<{ id: string }>()
  const [selectedGame, setSelectedGame] = useState<string>(loadItem('game') ?? Games.redDot)
  const [equation, setEquation] = useState<string | undefined>(loadItem('equation') ?? undefined)
  const [sets, setSets] = useState<string[]>()

  useEffect(() => {
    if (!selectedGame.includes('Numbers')) setEquation(undefined)
    removeItem('equation')
  }, [selectedGame])

  useEffect(() => {
    setSocketService(SocketService({ session: id ?? 'varl', userType: 'admin' }))
  }, [id])

  useEffect(() => {
    if (equation) saveItem('equation', equation)
  }, [equation])

  useEffect(() => {
    setLoading(true)
    if (socketService) {
      socketService.on('update', (clients: User[]) => {
        console.log('update', clients)
        setUsers(clients)
        let counter = 0
        clients?.forEach(client => {
          counter = client.displayData ? counter + 1 : counter
        })
        counter === clients?.length && counter > 0 ? setGameStarted(true) : setGameStarted(false)
      })
    }

    socketService?.on('equation', (equation: string) => {
      setEquation(equation)
    })

    socketService?.on('set', (set: SetItem[]) => {
      if (set && set.length === 0) {
        setSets(undefined)
        return
      }
      const setItems = set.map(setItem => `${setItem[0].id} - ${setItem[1].id} - ${setItem[2].id} \n `)
      setSets(setItems)
    })

    socketService?.on('error', (error: string) => {
      setEquation(error)
    })

    setLoading(false)
  }, [socketService])

  const getEquation = (): React.ReactNode => {
    if (equation) return equation
    if (sets) {
      return (
        <>
          <Typography>Set Items:</Typography>
          <Grid container spacing={1}>
            {sets.map(set => (
              <Grid key={set} item xs={6} md={12}>
                <Typography>{set}</Typography>
              </Grid>
            ))}
          </Grid>
        </>
      )
    }
    return undefined
  }

  const startGame = (gameName: string): void => {
    if (socketService) {
      switch (gameName) {
        case Games.redDot:
          startRedDotGame(socketService, Object.keys(Colors))
          break
        case Games.addNumbers:
          startAddNumbersGame(socketService)
          break
        case Games.subtractNumbers:
          startSubtractNumbersGame(socketService)
          break
        case Games.divideNumbers:
          startDivideNumbersGame(socketService)
          break
        case Games.multiplicateNumbers:
          startMultiplicateNumbersGame(socketService)
          break
        case Games.colors:
          startColorsGame(socketService, Object.keys(Colors))
          break
        case Games.forms:
          startFormsGame(socketService, Object.keys(Colors))
          break
        // case Games.set:
        //   startSetGame(socketService)
        //   break
        default:
      }
    }
  }

  if (loading) return <div>wird geladen</div>
  if (!socketService) return <div>Keine Verbindung zum Server hergestellt.</div>

  return (
    <Div100vh>
      <div className={classnames(classes.root, classes.rootOverride)}>
        <div className={classes.visualContainer}>
          <VisualContainer users={users} positioning={positioning} />
        </div>
        <div>
          <hr className={classes.divider} />
          <Grid container className={classes.controler} justify="center" alignContent="center" alignItems="center">
            <Grid item container direction={'row'} justify="center" xs={height > width ? 12 : 6}>
              <div className={classes.direction}>
                <div className={classes.controlTitle}>
                  <Typography className={classes.typography}>AUSRICHTUNG</Typography>
                </div>
                <div className={classes.controlDivider} />
                <Select
                  variant="outlined"
                  value={positioning}
                  onChange={event => {
                    saveItem('positioning', event.target.value as string)
                    setPositioning(event.target.value as 'line' | 'circle')
                  }}
                  className={classes.controlSelector}
                  classes={{ root: classes.selectRoot, iconOutlined: classes.iconOutlined, select: classes.select }}
                >
                  <MenuItem value={'circle'}>Kreis</MenuItem>
                  <MenuItem value={'line'}>Linie</MenuItem>
                </Select>
              </div>
              <div className={classes.connector}>
                <div className={classes.line} />
              </div>
              <div className={classes.direction}>
                <div className={classes.controlTitle}>
                  <Typography className={classes.typography}>SPIEL</Typography>
                </div>
                <div className={classes.controlDivider} />
                <Select
                  variant="outlined"
                  value={selectedGame}
                  onChange={event => {
                    saveItem('game', event.target.value as string)
                    setSelectedGame(event.target.value as string)
                  }}
                  classes={{ root: classes.selectRoot, iconOutlined: classes.iconOutlined, select: classes.select }}
                  className={classes.controlSelector}
                >
                  {Object.entries(GameDescriptions).map(game => (
                    <MenuItem key={game[0]} value={game[0]}>
                      {game[1]}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid
              item
              container
              direction={'row'}
              xs={height > width ? 12 : 6}
              justify="center"
              alignContent={'center'}
              alignItems={'center'}
            >
              <div className={classes.stopButtonWrapper}>
                <Button
                  size={'large'}
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {
                    stopGame(socketService)
                    removeItem('equation')
                  }}
                  className={classes.stopButton}
                >
                  Stop
                </Button>
              </div>
              <div className={classes.connector}>
                <div className={classes.line} />
              </div>
              <div className={classes.equation}>{getEquation()}</div>
              <div className={classes.connector}>
                <div className={classes.line} />
              </div>
              <div className={classes.startButtonWrapper}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => startGame(selectedGame)}
                  className={classes.startButton}
                >
                  {!gameStarted ? 'START' : 'WEITER'}
                </Button>
              </div>
            </Grid>
          </Grid>
          <hr className={classes.divider} />
          <div className={classes.gameControl}></div>
          {/* <div className={classes.formSettings}></div> */}
          {/* <ImageSelect setImage={setImage} image={image} /> */}
          {/* <UserList users={users} removeUser={user => resetClientId(socketService, user)} /> */}
        </div>
      </div>
      <Button variant="outlined" className={classes.resetButton} onClick={() => resetClients(socketService)}>
        Reset
      </Button>
    </Div100vh>
  )
}

export default AdminView
