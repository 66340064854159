import React, { useEffect, useState, ReactElement } from 'react'
import { SocketService } from '../../services/socket.service'
import { Stage, Layer, Path, Text } from 'react-konva'
import { FormComponent } from '../../components/Forms'
import useWindowDimensions from '../../hooks/windowDimensions'
import { makeStyles, Typography } from '@material-ui/core'
import { DisplayData } from '../../interfaces'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  id: {
    textAlign: 'center',
    fontSize: '50vh',
    color: 'white',
    backgroundColor: '#132332',
    height: '70vh',
  },
  stage: {
    backgroundColor: '#132332',
    height: '70vh',
  },
  top: {
    height: '10vh',
    backgroundImage: 'url(/user-top.png)',
    color: 'white',
    display: 'flex',
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
  },
  topText: {
    width: '100%',
    textAlign: 'center',
    fontSize: '7vh',
    fontWeight: 'bold',
    textShadow: '2 2 #dddddd',
  },
  bottom: {
    height: '10vh',
    backgroundImage: 'url(/user-bottom.png)',
  },
  middle: {
    height: '70vh',
  },
  divider: {
    borderTop: '3px solid #3D8434',
    marginTop: 'calc(1.5vh - 1px)',
    marginBottom: 'calc(1.5vh - 3px)',
  },
}))

export const UserView: React.FC = (): ReactElement => {
  const classes = useStyles()
  const [userId, setUserId] = useState<number>()
  const [displayData, setDisplayData] = useState<DisplayData>()
  const { width, height } = useWindowDimensions()
  const [socketService, setSocketService] = useState<SocketIOClient.Socket>()
  const { id: session } = useParams<{ id: string }>()

  const resetId = (): void => {
    setUserId(undefined)
  }

  useEffect(() => {
    setSocketService(SocketService(userId ? { session, userId: Number(userId) } : { session: session ?? 'varl' }))
    // eslint-disable-next-line
  }, [session])

  useEffect(() => {
    if (socketService) {
      socketService.on('connected', (newUserId: string) => {
        if (Number(newUserId) !== userId) {
          setUserId(Number(newUserId))
        }
      })

      socketService.on('update', (data?: DisplayData) => {
        setDisplayData(data)
      })

      socketService.on('reset', () => {
        setDisplayData(undefined)
      })

      socketService.on('disconnect', () => {
        resetId()
        setDisplayData(undefined)
      })
    }
  }, [socketService, userId])

  const getContent = (): React.ReactNode => {
    if (!displayData || displayData.type === 'number') {
      return <div className={classes.id}>{displayData?.value || userId || ''}</div>
    }
    return (
      <Stage width={window.innerWidth} height={window.innerHeight * 0.7} className={classes.stage}>
        <Layer>
          {displayData.type === 'form' && (
            <FormComponent image={displayData.form} width={width} height={height * 0.7} />
          )}
        </Layer>
      </Stage>
    )
  }

  return (
    <>
      <div className={classes.top}>
        {displayData?.equation && <Typography className={classes.topText}>{displayData.equation}</Typography>}
      </div>
      <hr className={classes.divider} />

      <div className={classes.middle}>{getContent()}</div>
      <hr className={classes.divider} />

      <div className={classes.bottom}>
        <Stage width={window.innerWidth} height={window.innerHeight * 0.1}>
          <Layer>
            <Path
              // data="M 13 5 C 14 3 15.3333 3 16 3 C 34 3 54 3 73 3 C 75 3 74 5 74 5 L 65 21 C 64 23 62.6667 23 62 23 L 6 23 C 4 23 4.6667 21.6667 5 21 Z"
              data="M 11 9 C 13 3 19 3 19 3 L 67 3 C 75 3 72 10 72 10 L 67 21 C 65 26 60 26 60 26 L 12 26 C 4 26 7 20 7 20 Z"
              fill="black"
              scaleX={2}
              scaleY={2}
              opacity={0.4}
              x={width / 2 - 80}
              y={(height * 0.1) / 2 - 30}
            />
            <Path
              // data="M 13 5 C 14 3 15.3333 3 16 3 C 34 3 54 3 73 3 C 75 3 74 5 74 5 L 65 21 C 64 23 62.6667 23 62 23 L 6 23 C 4 23 4.6667 21.6667 5 21 Z"
              data="M 11 9 C 13 3 19 3 19 3 L 67 3 C 75 3 72 10 72 10 L 67 21 C 65 26 60 26 60 26 L 12 26 C 4 26 7 20 7 20 Z"
              scaleX={2}
              scaleY={2}
              x={width / 2 - 78}
              y={(height * 0.1) / 2 - 28}
              stroke={'grey'}
              strokeWidth={2}
            />
            <Path
              // data="M 13 5 C 14 3 15.3333 3 16 3 C 34 3 54 3 73 3 C 75 3 74 5 74 5 L 65 21 C 64 23 62.6667 23 62 23 L 6 23 C 4 23 4.6667 21.6667 5 21 Z"
              data="M 11 9 C 13 3 19 3 19 3 L 67 3 C 75 3 72 10 72 10 L 67 21 C 65 26 60 26 60 26 L 12 26 C 4 26 7 20 7 20 Z"
              scaleX={2}
              scaleY={2}
              x={width / 2 - 80}
              y={(height * 0.1) / 2 - 30}
              stroke={'white'}
              strokeWidth={2}
            />
            <Text
              x={width / 2 - 10}
              y={(height * 0.1) / 2 - 14}
              align="center"
              text={userId ? `${userId}` : ''}
              fontSize={30}
              fontFamily="Calibri"
              fill="#fff"
              verticalAlign="bottom"
            />
          </Layer>
        </Stage>
      </div>
      <hr className={classes.divider} />
    </>
  )
}

export default UserView
