import React from 'react'
import { Group, Text, Circle, Path } from 'react-konva'

const FormWithText = ({
  label,
  x,
  y,
  size,
  children,
  description,
  winner,
  horizontal,
}: {
  label: string
  x: number
  y: number
  size: number
  children: React.ReactNode
  description: string
  winner: boolean
  horizontal: boolean
}) => {
  let data

  if (winner) {
    data = 'M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'
  } else {
    data =
      'M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z'
  }

  return (
    <Group x={x} y={y} width={size} height={size}>
      {children}
      <Text
        x={-(size * 2) + (horizontal ? 82 : 77)}
        y={horizontal ? -1 : 0}
        align="center"
        text={description || ''}
        fontSize={24}
        fontFamily="Calibri"
        fill="#fff"
        verticalAlign="bottom"
      />
      <Circle radius={17} fill="grey" x={horizontal ? -(size * 2) + 119 : -(size * 2) + 84} y={horizontal ? 9 : 42} />
      <Circle radius={15} fill="white" x={horizontal ? -(size * 2) + 119 : -(size * 2) + 84} y={horizontal ? 9 : 42} />
      {typeof winner === 'boolean' && (
        <Path
          x={
            horizontal ? (winner ? -(size * 2) + 106 : -(size * 2) + 109) : winner ? -(size * 2) + 71 : -(size * 2) + 74
          }
          y={horizontal ? -1 : 32}
          data={data}
          fill={winner ? 'green' : 'red'}
          scaleX={winner ? 1 : 0.8}
          scaleY={winner ? 1 : 0.8}
        />
      )}
    </Group>
  )
}

export default FormWithText
