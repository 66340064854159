export const Colors: { [label: string]: string } = {
  blue: '#0066ff',
  green: '#00ff00',
  // orange: '#ffa500',
  red: '#ff0000',
  // purple: '#ff00ff',
  // yellow: '#ffff00',
  white: 'white',
}

export const Forms: { [label: string]: string } = {
  Rect: 'rect',
  Circle: 'circle',
  Polygon: 'polygon',
}

export const Edges: { [label: string]: string } = {
  '3': '3',
  '5': '5',
  '7': '7',
  '9': '9',
}

export const Games: { [label: string]: string } = {
  redDot: 'redDotGame',
  addNumbers: 'addNumbersGame',
  subtractNumbers: 'subtractNumbersGame',
  multiplicateNumbers: 'multiplicateNumbersGame',
  divideNumbers: 'divideNumbersGame',
  // set: 'setGame',
  colors: 'colorsGame',
  forms: 'formsGame',
}

export const GameDescriptions: { [label: string]: string } = {
  redDotGame: 'Finde den Roten Kreis',
  addNumbersGame: 'Nummer addieren',
  subtractNumbersGame: 'Nummer subtrahieren',
  multiplicateNumbersGame: 'Nummer multiplizieren',
  divideNumbersGame: 'Nummer dividieren',
  // setGame: 'Set Spiel',
  colorsGame: 'Farben Spiel',
  formsGame: 'Formen Spiel',
}
