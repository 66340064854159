import React from 'react'
import { Circle as CircleKonva, Rect as RectKonva, Path } from 'react-konva'
import { Colors } from '../constants'
import { Form } from '../interfaces'

interface FormComponent {
  size: number
  color: string
  xCoord?: number
  yCoord?: number
  draggable?: boolean
  winner?: boolean
  admin?: boolean
}

interface Polygon extends FormComponent {
  sides: number
}

export const Triangle = () => {
  return (
    <Path data="M70.401923788647 11.048094716167a3 3 0 0 1 5.1961524227066 0l69.803847577293 120.90381056767a3 3 0 0 1 -2.5980762113533 4.5l-139.60769515459 0a3 3 0 0 1 -2.5980762113533 -4.5"></Path>
  )
}

export const Polygon = ({ sides, size, color, xCoord, yCoord, admin, draggable = false, winner = false }: Polygon) => {
  return (
    <Path
      x={(xCoord ?? 0) - size / 2}
      y={(yCoord ?? 0) - size / 2 - 2}
      fill={color}
      scaleX={size / 30}
      scaleY={size / 30}
      stroke={'#333333'}
      strokeWidth={admin ? 1 : 0}
      data="M 14.134 2.5766 a 1 1 0 0 1 1.7321 0 l 13.7679 23.8468 a 1 1 0 0 1 -0.866 1.5 l -27.5359 0 a 1 1 0 0 1 -0.866 -1.5 L 14.134 2.5766"
    />
  )
}

export const Circle = ({ size, color, xCoord, yCoord, draggable = false, winner = false }: FormComponent) => {
  return (
    <CircleKonva
      x={xCoord}
      y={yCoord}
      radius={size / 2}
      fill={color}
      draggable={draggable}
      stroke={'#333333'}
      strokeWidth={1}
    />
  )
}

export const Rect = ({ size, color, xCoord, yCoord, draggable = false, winner = false }: FormComponent) => {
  return (
    <RectKonva
      x={xCoord}
      y={yCoord}
      height={size}
      width={size}
      fill={color}
      draggable={draggable}
      stroke={'#333333'}
      strokeWidth={1}
      cornerRadius={size / 50}
    />
  )
}

export const FormComponent = ({
  image,
  width,
  height,
}: {
  image?: Form | undefined
  width: number
  height: number
}) => {
  const lengthOfSmallestWindowSide = width <= height ? width : height
  // add padding
  const middleOfWindow = width / 2
  const formSize = lengthOfSmallestWindowSide - 60

  let form = null
  switch (image?.type) {
    case 'rect':
      form = <Rect color={Colors[image.color]} xCoord={middleOfWindow - formSize / 2} yCoord={30} size={formSize} />
      break
    case 'circle':
      form = <Circle color={Colors[image.color]} xCoord={middleOfWindow} yCoord={30 + formSize / 2} size={formSize} />
      break
    case 'polygon':
      form = (
        <Polygon
          color={Colors[image.color]}
          sides={image.edges ? Number(image.edges) : 3}
          xCoord={middleOfWindow}
          yCoord={30 + formSize / 2}
          size={formSize}
        />
      )
      break
    default:
  }

  return form
}
