import socketIOClient from 'socket.io-client'
import { User, Form } from '../interfaces'
import { Colors, Forms, Games } from '../constants'

interface SocketOptions {
  userType?: 'admin' | 'user'
  userId?: number | null
  session: string
}

export const SocketService = (options: SocketOptions = { session: 'varl' }): SocketIOClient.Socket => {
  const server = process.env.REACT_APP_CAM_SERVER_URL ?? 'http://85.215.154.20:4000'

  return socketIOClient(server, { query: options })
}

export const sendMessage = (socketService: SocketIOClient.Socket, content: string, receiver: string): void => {
  socketService.emit('privatemessage', {
    receiver,
    content,
  })
}

export const startGame = (socketService: SocketIOClient.Socket, image: Form) => {
  socketService.emit('update', {
    image: image,
    forms: Object.values(Forms),
    colors: Object.keys(Colors),
  })
}

export const resetClientId = (socketService: SocketIOClient.Socket, receiver: User): void => {
  socketService.emit('resetClientId', receiver)
}

export const resetClients = (socketService: SocketIOClient.Socket) => {
  socketService.emit('resetClients')
}

export const startRedDotGame = (socketService: SocketIOClient.Socket, colorList: string[]) => {
  socketService.emit(Games.redDot, { colorList })
}

export const startColorsGame = (socketService: SocketIOClient.Socket, colorList: string[]) => {
  socketService.emit(Games.colors, { colorList })
}

export const startFormsGame = (socketService: SocketIOClient.Socket, colorList: string[]) => {
  socketService.emit(Games.forms, { colorList })
}

export const startAddNumbersGame = (socketService: SocketIOClient.Socket) => {
  socketService.emit(Games.addNumbers)
}

export const startSubtractNumbersGame = (socketService: SocketIOClient.Socket) => {
  socketService.emit(Games.subtractNumbers)
}

export const startMultiplicateNumbersGame = (socketService: SocketIOClient.Socket) => {
  socketService.emit(Games.multiplicateNumbers)
}

export const startDivideNumbersGame = (socketService: SocketIOClient.Socket) => {
  socketService.emit(Games.divideNumbers)
}

export const startSetGame = (socketService: SocketIOClient.Socket) => {
  socketService.emit(Games.set)
}

export const stopGame = (socketService: SocketIOClient.Socket) => {
  socketService.emit('stopGame')
}
