import React from 'react'
import { makeStyles, Grid, useMediaQuery, Theme } from '@material-ui/core'
import { Stage, Layer, Ring as RingKonva, Path, Text } from 'react-konva'
import useWindowDimensions from '../hooks/windowDimensions'
import { User, Form } from '../interfaces'
import { Circle, Polygon, Rect } from './Forms'
import { Colors } from '../constants'
import FormWithText from './FormWithText'

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: '#dddddd',
    height: '50vh',
    backgroundImage: 'url(/background-top.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },
  },
  content: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-around',
  },
  settings: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
    height: '100%',
  },
  equation: {
    whiteSpace: 'pre-wrap',
  },
  divider: {
    borderTop: '3px solid #3D8434',
  },
  controller: {
    backgroundColor: '#132332',
    height: '30vh',
    display: 'flex',
    width: '100%',
    alignContent: 'center',
  },
  direction: {
    borderRadius: '50%',
    height: '25vh',
    width: '25vh',
    border: '3px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    color: 'white',
    flexDirection: 'column',
    zIndex: 2,
    backgroundColor: '#132332',
  },
  controlDivider: {
    width: '100%',
    borderTop: '1px solid grey',
    boxSizing: 'border-box',
    zIndex: 1,
  },
  connector: {
    width: 20,
  },
}))

export const VisualContainer = ({ users, positioning }: { users: User[]; positioning?: 'line' | 'circle' }) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const LayerHeight = 0.5
  const LayerWidth = 1

  const classes = useStyles({ matches })
  const { height, width } = useWindowDimensions()

  const lengthOfSmallestWindowSide =
    width * LayerWidth <= height * LayerHeight ? width * LayerWidth : height * LayerHeight

  const getForm = ({ form, winner, horizontal }: { form: Form; winner: boolean; horizontal: boolean }) => {
    if (form.type === 'circle') {
      return (
        <Circle
          size={30}
          color={Colors[form.color]}
          xCoord={horizontal ? 40 : -35}
          yCoord={horizontal ? 9 : 80}
          winner={winner}
          admin
        />
      )
    } else if (form.type === 'rect') {
      return (
        <Rect
          size={28}
          color={Colors[form.color]}
          xCoord={horizontal ? 25 : -50}
          yCoord={horizontal ? -5 : 66}
          winner={winner}
          admin
        />
      )
    } else {
      return (
        <Polygon
          color={Colors[form.color]}
          size={30}
          sides={form.edges ? Number(form.edges) : 3}
          xCoord={horizontal ? 39 : -35}
          yCoord={horizontal ? 10 : 80}
          winner={winner}
          admin
        />
      )
    }
  }

  const getLineCoordinates = (number: number, amount: number, matches: boolean) => [
    ((width * LayerWidth) / (amount + 1)) * number + 28,
    height * 0.5 * 0.5 - 70,
  ]
  // https://stackoverflow.com/questions/155649/circle-coordinates-to-array-in-javascript
  const getCircleCoordinates = (number: number, amount: number) => {
    let x =
      (width * LayerWidth) / 2 + (lengthOfSmallestWindowSide / 2) * Math.cos(2 * Math.PI * (number / amount + 45 / 180))
    let y =
      (height * LayerHeight) / 2 +
      (lengthOfSmallestWindowSide / 2 - 80) * Math.sin(2 * Math.PI * (number / amount + 45 / 180))

    if (Math.abs(x - width / 2) < 5) {
      if (y < (height * LayerHeight) / 2) {
        y = y - 25
      } else {
        y = y + 25
      }
    }

    if (x - 70 < 0) {
      x = 60
    } else if (x + 96 > width) {
      x = width - 96
    }

    return [x, y - 10]
  }

  const getResult = (user: User) => {
    const type = user.displayData?.type
    const label = user.displayData?.value

    if (type === 'form' && user) {
      return getForm({
        form: user.displayData!.form!,
        winner: user.displayData!.winner!,
        horizontal: positioning === 'circle',
      })
    } else if ((type === 'number' && label) || label === 0) {
      const labelLength = `${label}`.length
      return (
        <Text
          x={positioning === 'circle' ? (labelLength < 2 ? 32 : 26) : labelLength < 2 ? -44 : -48}
          y={positioning === 'circle' ? 0 : 70}
          align="center"
          text={`${label}`}
          fontSize={24}
          fontFamily="Calibri"
          fill="#fff"
          verticalAlign="bottom"
        />
      )
    } else {
      return (
        <Circle
          size={30}
          xCoord={positioning === 'circle' ? 40 : -36}
          yCoord={positioning === 'circle' ? 8 : 80}
          color="grey"
        />
      )
    }
  }
  const getRadius = () => {
    let radius = lengthOfSmallestWindowSide / 2 - 160
    if (radius < 0) return 1
    return radius
  }
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Stage width={width * LayerWidth} height={height * LayerHeight}>
          <Layer>
            {positioning === 'circle' && (
              <RingKonva
                x={(width * LayerWidth) / 2}
                y={(height * LayerHeight) / 2}
                innerRadius={getRadius()}
                outerRadius={getRadius() + 4}
                fill={'white'}
              />
            )}
            {users
              ?.sort((userA, userB) => userA.id - userB.id)
              .map((user, index) => {
                const [xCoord, yCoord] =
                  positioning === 'line'
                    ? getLineCoordinates(index + 1, users.length, matches)
                    : getCircleCoordinates(index, users.length)

                return (
                  <>
                    <Path
                      // data="M 13 5 C 14 3 15.3333 3 16 3 C 34 3 54 3 73 3 C 75 3 74 5 74 5 L 65 21 C 64 23 62.6667 23 62 23 L 6 23 C 4 23 4.6667 21.6667 5 21 Z"
                      data="M 11 9 C 13 3 19 3 19 3 L 67 3 C 75 3 72 10 72 10 L 67 21 C 65 26 60 26 60 26 L 12 26 C 4 26 7 20 7 20 Z"
                      fill="black"
                      scaleX={2}
                      scaleY={2}
                      opacity={0.4}
                      x={xCoord - (positioning === 'circle' ? 70 : 8)}
                      y={yCoord - (positioning === 'circle' ? 20 : 30)}
                      rotation={positioning === 'line' ? 90 : 0}
                    />
                    <Path
                      // data="M 13 5 C 14 3 15.3333 3 16 3 C 34 3 54 3 73 3 C 75 3 74 5 74 5 L 65 21 C 64 23 62.6667 23 62 23 L 6 23 C 4 23 4.6667 21.6667 5 21 Z"
                      data="M 11 9 C 13 3 19 3 19 3 L 67 3 C 75 3 72 10 72 10 L 67 21 C 65 26 60 26 60 26 L 12 26 C 4 26 7 20 7 20 Z"
                      scaleX={2}
                      scaleY={2}
                      x={xCoord - (positioning === 'circle' ? 68 : 6)}
                      y={yCoord - (positioning === 'circle' ? 18 : 28)}
                      stroke={'grey'}
                      strokeWidth={2}
                      rotation={positioning === 'line' ? 90 : 0}
                    />
                    <Path
                      // data="M 13 5 C 14 3 15.3333 3 16 3 C 34 3 54 3 73 3 C 75 3 74 5 74 5 L 65 21 C 64 23 62.6667 23 62 23 L 6 23 C 4 23 4.6667 21.6667 5 21 Z"
                      data="M 11 9 C 13 3 19 3 19 3 L 67 3 C 75 3 72 10 72 10 L 67 21 C 65 26 60 26 60 26 L 12 26 C 4 26 7 20 7 20 Z"
                      scaleX={2}
                      scaleY={2}
                      x={xCoord - (positioning === 'circle' ? 70 : 8)}
                      y={yCoord - (positioning === 'circle' ? 20 : 30)}
                      stroke={'white'}
                      strokeWidth={2}
                      rotation={positioning === 'line' ? 90 : 0}
                    />
                    <FormWithText
                      key={user.id}
                      label={user.displayData?.type === 'number' ? `${user.displayData.value!}` : ''}
                      size={60}
                      x={xCoord}
                      y={yCoord}
                      description={`${user.id}`}
                      winner={user.displayData?.winner ?? false}
                      horizontal={positioning === 'circle'}
                    >
                      {getResult(user)}
                    </FormWithText>
                  </>
                )
              })}
          </Layer>
        </Stage>
      </Grid>
    </Grid>
  )
}
